import React from "react";
import ReactDOM from "react-dom";

const homeProductsRoot = document.getElementById("homeProductsRoot");

class HomeProductsPortal extends React.Component {
  constructor(props) {
    super(props);
    this.homeProductsEl = document.createElement('div');
  }

  componentDidMount() {
    if (homeProductsRoot != null)
    {
        homeProductsRoot.appendChild(this.homeProductsEl);
    }
  }

  render() {
    // Use a portal to render the children into the element
    return ReactDOM.createPortal(
      // Any valid React child: JSX, strings, arrays, etc.
      this.props.children,
      this.homeProductsEl,
    );
  }
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: []
    }
  }

  componentDidMount() {
    
    var productPartialModel = new Object();
    productPartialModel.numberOfProducts = this.state.productList.length;
    productPartialModel.sortByType = "popular";
    
    fetch('get-home-products-partial', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(productPartialModel)
    })
    .then((res) => res.json())
    .then(data => {
      if (data.errorMessage == "error") {
        window.location.href = window.location.origin + "/error"
      } 
      else {
        this.setState({ productList: data });
      }
      
      // if number of products displayed is less than length of all product divs
      // and user is scrolling down show next 12 products
      var scrollPos = 0;
      window.addEventListener("scroll", (e) => {
        e.preventDefault();
        if (this.state.productList.length < 500) {
          if ((document.body.getBoundingClientRect()).bottom < scrollPos) {
            const windowHeight = window.innerHeight;
            const fullHeight = document.documentElement.scrollHeight;
            const currentPosition = window.scrollY;
            const scrollPercentageRounded = Math.round((currentPosition / (fullHeight - windowHeight)) * 100);
            if (scrollPercentageRounded == 100) {
              
              document.getElementById("loaderGet").style.display = "flex";
              document.getElementById("loaderGetTxt").style.display = "flex";
              
              var productPartialModel = new Object();
              productPartialModel.numberOfProducts = this.state.productList.length;
              productPartialModel.sortByType = "popular";
            
              fetch('get-home-products-partial', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(productPartialModel)
              })
              .then(response => {
                return response.json()
              })
              .then(newData => {
                
                document.getElementById("loaderGet").style.display = "none";
                document.getElementById("loaderGetTxt").style.display = "none";
                
                var concatProdList = this.state.productList.concat(newData)
                var noDuplicatesProductList = this.removedDuplicatesProductsList(concatProdList)
  
                this.setState({ productList: noDuplicatesProductList });
              });
            }
          }
          // saves the new position for iteration.
          scrollPos = (document.body.getBoundingClientRect()).bottom;
        }
      });
      
    });
  }

  removedDuplicatesProductsList = (productList) => { 
    const uniq = {};
    const prodArrFiltered = productList.filter(prod => !uniq[prod.productId] && (uniq[prod.productId] = true));

    return prodArrFiltered;
  }

  render() {
    const { productList } = this.state
    
    return (
      <div>
        <HomeProductsPortal>
          <div className="loader-store-products" id="loaderGet"></div>
          <div style={{marginLeft: "5px"}} className="loading-txt-store-products" id="loaderGetTxt">getting products...</div>
          
          {
            productList.map((prod) => (
              <div className="responsive2">
                <a href={`/products/${prod.productId}/${prod.productUrlName}`} className="product-link-cardview-full">
                  <div className="gallery2">
                    <div className="responsive-container" style={{ backgroundImage: `url(${prod.productImgUrl})` }}></div>
                  
                    <div className="product-cardview-full-grid-col-container">
                        <div className="product-grid-cardview-full-col-1">
                            <img className="product-cardview-full-profile-picture" src={prod.profileImgUrl} />
                        </div>
                        
                        <div className="product-grid-cardview-full-col-2" style={{position: "relative"}}>
                            <div title={prod.productNameFull} className="product-name-grid-cardview-full-col-2">{prod.productName}</div>
                            <div className="store-name-grid-cardview-full-col-2">{prod.storeName}</div>
                            <div className="product-location-grid-cardview-full-col-2">{`${prod.storeCity} • ${prod.storeProvinceOrState}`}</div>
                            <div className="product-elapsed-upload-time-grid-cardview-full-col-2">{prod.elapsedUploadTime}</div>
                            <div className="product-price-grid-cardview-full-col-2">{"$" + prod.productPrice}</div> 
                        </div>
                    </div>
                  
                  </div>
                </a>
              </div>
            ))
          }
        </HomeProductsPortal>
      </div>
    );
  }
}
export default Home;